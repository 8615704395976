/* eslint-disable import/order */
import { useStore } from '@nanostores/react'
import { langs, type Lang } from '@svag/shared/src/lang'
import i18n from 'i18next'
import Cookies from 'js-cookie'
import { atom } from 'nanostores'
import React, { useCallback, useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { Trans, initReactI18next, useTranslation, type UseTranslationOptions } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loader } from '../components/ui/Loader'
import { getLangByLocationPathname } from '../utils/pumpGetRoute'
import { useMe } from './ctx'
import { trackUserData } from './mixpanel'
import { notify } from './notify'
import { trpc } from './trpc'
import locale from '../locale.json'
import { formatDate } from '@svag/shared/src/date'

// @index('../**/locale/(en|ru).json', f => `// import ${_.camelCase(f.path)} from '${f.path}${f.ext}'`)
// import componentsAuthAuthLocaleEn from '../components/auth/Auth/locale/en.json'
// import componentsAuthAuthLocaleRu from '../components/auth/Auth/locale/ru.json'
// import componentsAuthConfirmEmailBlockerLocaleEn from '../components/auth/ConfirmEmailBlocker/locale/en.json'
// import componentsAuthConfirmEmailBlockerLocaleRu from '../components/auth/ConfirmEmailBlocker/locale/ru.json'
// import componentsAuthRestorePasswordLocaleEn from '../components/auth/RestorePassword/locale/en.json'
// import componentsAuthRestorePasswordLocaleRu from '../components/auth/RestorePassword/locale/ru.json'
// import componentsAuthSignInLocaleEn from '../components/auth/SignIn/locale/en.json'
// import componentsAuthSignInLocaleRu from '../components/auth/SignIn/locale/ru.json'
// import componentsAuthSignUpLocaleEn from '../components/auth/SignUp/locale/en.json'
// import componentsAuthSignUpLocaleRu from '../components/auth/SignUp/locale/ru.json'
// import componentsBillingPurchaseProductLocaleEn from '../components/billing/PurchaseProduct/locale/en.json'
// import componentsBillingPurchaseProductLocaleRu from '../components/billing/PurchaseProduct/locale/ru.json'
// import componentsBillingPurchaseProductPaymentButtonPawLocaleEn from '../components/billing/PurchaseProduct/PaymentButtonPaw/locale/en.json'
// import componentsBillingPurchaseProductPaymentButtonPawLocaleRu from '../components/billing/PurchaseProduct/PaymentButtonPaw/locale/ru.json'
// import componentsBillingPurchaseProductPaymentButtonVerifoneLocaleEn from '../components/billing/PurchaseProduct/PaymentButtonVerifone/locale/en.json'
// import componentsBillingPurchaseProductPaymentButtonVerifoneLocaleRu from '../components/billing/PurchaseProduct/PaymentButtonVerifone/locale/ru.json'
// import componentsEducationCardsConsultationCardLocaleEn from '../components/education/cards/ConsultationCard/locale/en.json'
// import componentsEducationCardsConsultationCardLocaleRu from '../components/education/cards/ConsultationCard/locale/ru.json'
// import componentsEducationCardsEdCardLocaleEn from '../components/education/cards/EdCard/locale/en.json'
// import componentsEducationCardsEdCardLocaleRu from '../components/education/cards/EdCard/locale/ru.json'
// import componentsEducationCardsGroupCardGroupCardActiveStudentLocaleEn from '../components/education/cards/GroupCard/GroupCardActiveStudent/locale/en.json'
// import componentsEducationCardsGroupCardGroupCardActiveStudentLocaleRu from '../components/education/cards/GroupCard/GroupCardActiveStudent/locale/ru.json'
// import componentsEducationCardsGroupCardGroupCardDefaultLocaleEn from '../components/education/cards/GroupCard/GroupCardDefault/locale/en.json'
// import componentsEducationCardsGroupCardGroupCardDefaultLocaleRu from '../components/education/cards/GroupCard/GroupCardDefault/locale/ru.json'
// import componentsEducationCardsGroupCardGroupCardTeacherLocaleEn from '../components/education/cards/GroupCard/GroupCardTeacher/locale/en.json'
// import componentsEducationCardsGroupCardGroupCardTeacherLocaleRu from '../components/education/cards/GroupCard/GroupCardTeacher/locale/ru.json'
// import componentsEducationCardsGuideCardLocaleEn from '../components/education/cards/GuideCard/locale/en.json'
// import componentsEducationCardsGuideCardLocaleRu from '../components/education/cards/GuideCard/locale/ru.json'
// import componentsFormDatePickerLocaleEn from '../components/form/DatePicker/locale/en.json'
// import componentsFormDatePickerLocaleRu from '../components/form/DatePicker/locale/ru.json'
// import componentsFormSelectLocaleEn from '../components/form/Select/locale/en.json'
// import componentsFormSelectLocaleRu from '../components/form/Select/locale/ru.json'
// import componentsGeneralMembersLocaleEn from '../components/general/Members/locale/en.json'
// import componentsGeneralMembersLocaleRu from '../components/general/Members/locale/ru.json'
// import componentsGeneralProjectsLocaleEn from '../components/general/Projects/locale/en.json'
// import componentsGeneralProjectsLocaleRu from '../components/general/Projects/locale/ru.json'
// import componentsLayoutsDashboardLayoutLocaleEn from '../components/layouts/DashboardLayout/locale/en.json'
// import componentsLayoutsDashboardLayoutLocaleRu from '../components/layouts/DashboardLayout/locale/ru.json'
// import componentsLayoutsGeneralLayoutLocaleEn from '../components/layouts/GeneralLayout/locale/en.json'
// import componentsLayoutsGeneralLayoutLocaleRu from '../components/layouts/GeneralLayout/locale/ru.json'
// import componentsLayoutsGuideLayoutLessonsMenuLocaleEn from '../components/layouts/GuideLayout/LessonsMenu/locale/en.json'
// import componentsLayoutsGuideLayoutLessonsMenuLocaleRu from '../components/layouts/GuideLayout/LessonsMenu/locale/ru.json'
// import componentsLayoutsGuideLayoutLocaleEn from '../components/layouts/GuideLayout/locale/en.json'
// import componentsLayoutsGuideLayoutLocaleRu from '../components/layouts/GuideLayout/locale/ru.json'
// import componentsLayoutsIntroLayoutLocaleEn from '../components/layouts/IntroLayout/locale/en.json'
// import componentsLayoutsIntroLayoutLocaleRu from '../components/layouts/IntroLayout/locale/ru.json'
// import componentsOtherGithubInvitationLocaleEn from '../components/other/GithubInvitation/locale/en.json'
// import componentsOtherGithubInvitationLocaleRu from '../components/other/GithubInvitation/locale/ru.json'
// import componentsOtherPublicUserPreviewLocaleEn from '../components/other/PublicUserPreview/locale/en.json'
// import componentsOtherPublicUserPreviewLocaleRu from '../components/other/PublicUserPreview/locale/ru.json'
// import componentsPagesAccessDeniedPageComponentLocaleEn from '../components/pages/AccessDeniedPageComponent/locale/en.json'
// import componentsPagesAccessDeniedPageComponentLocaleRu from '../components/pages/AccessDeniedPageComponent/locale/ru.json'
// import componentsPagesAuthorizedOnlyPageComponentLocaleEn from '../components/pages/AuthorizedOnlyPageComponent/locale/en.json'
// import componentsPagesAuthorizedOnlyPageComponentLocaleRu from '../components/pages/AuthorizedOnlyPageComponent/locale/ru.json'
// import componentsPagesErrorPageComponentLocaleEn from '../components/pages/ErrorPageComponent/locale/en.json'
// import componentsPagesErrorPageComponentLocaleRu from '../components/pages/ErrorPageComponent/locale/ru.json'
// import componentsPagesNotFoundPageComponentLocaleEn from '../components/pages/NotFoundPageComponent/locale/en.json'
// import componentsPagesNotFoundPageComponentLocaleRu from '../components/pages/NotFoundPageComponent/locale/ru.json'
// import localeEn from '../locale/en.json'
// import localeRu from '../locale/ru.json'
// import pagesAccountCommuinityPageCommunityDiscordLocaleEn from '../pages/account/CommuinityPage/CommunityDiscord/locale/en.json'
// import pagesAccountCommuinityPageCommunityDiscordLocaleRu from '../pages/account/CommuinityPage/CommunityDiscord/locale/ru.json'
// import pagesAccountCommuinityPageCommunityTelegramLocaleEn from '../pages/account/CommuinityPage/CommunityTelegram/locale/en.json'
// import pagesAccountCommuinityPageCommunityTelegramLocaleRu from '../pages/account/CommuinityPage/CommunityTelegram/locale/ru.json'
// import pagesAccountCommuinityPageLocaleEn from '../pages/account/CommuinityPage/locale/en.json'
// import pagesAccountCommuinityPageLocaleRu from '../pages/account/CommuinityPage/locale/ru.json'
// import pagesAccountDashboardPageLocaleEn from '../pages/account/DashboardPage/locale/en.json'
// import pagesAccountDashboardPageLocaleRu from '../pages/account/DashboardPage/locale/ru.json'
// import pagesAccountEditProfilePageLocaleEn from '../pages/account/EditProfilePage/locale/en.json'
// import pagesAccountEditProfilePageLocaleRu from '../pages/account/EditProfilePage/locale/ru.json'
// import pagesAccountEducationPageLocaleEn from '../pages/account/EducationPage/locale/en.json'
// import pagesAccountEducationPageLocaleRu from '../pages/account/EducationPage/locale/ru.json'
// import pagesAccountGuidesPageLocaleEn from '../pages/account/GuidesPage/locale/en.json'
// import pagesAccountGuidesPageLocaleRu from '../pages/account/GuidesPage/locale/ru.json'
// import pagesAuthConfirmEmailPageLocaleEn from '../pages/auth/ConfirmEmailPage/locale/en.json'
// import pagesAuthConfirmEmailPageLocaleRu from '../pages/auth/ConfirmEmailPage/locale/ru.json'
// import pagesAuthConfirmNewEmailPageLocaleEn from '../pages/auth/ConfirmNewEmailPage/locale/en.json'
// import pagesAuthConfirmNewEmailPageLocaleRu from '../pages/auth/ConfirmNewEmailPage/locale/ru.json'
// import pagesAuthResetPasswordPageLocaleEn from '../pages/auth/ResetPasswordPage/locale/en.json'
// import pagesAuthResetPasswordPageLocaleRu from '../pages/auth/ResetPasswordPage/locale/ru.json'
// import pagesAuthRestorePasswordPageLocaleEn from '../pages/auth/RestorePasswordPage/locale/en.json'
// import pagesAuthRestorePasswordPageLocaleRu from '../pages/auth/RestorePasswordPage/locale/ru.json'
// import pagesAuthSignInPageLocaleEn from '../pages/auth/SignInPage/locale/en.json'
// import pagesAuthSignInPageLocaleRu from '../pages/auth/SignInPage/locale/ru.json'
// import pagesAuthSignUpPageLocaleEn from '../pages/auth/SignUpPage/locale/en.json'
// import pagesAuthSignUpPageLocaleRu from '../pages/auth/SignUpPage/locale/ru.json'
// import pagesBillingReturnPawPageLocaleEn from '../pages/billing/ReturnPawPage/locale/en.json'
// import pagesBillingReturnPawPageLocaleRu from '../pages/billing/ReturnPawPage/locale/ru.json'
// import pagesConsultationConsultationPageConsultationPageAttendeeLocaleEn from '../pages/consultation/ConsultationPage/ConsultationPageAttendee/locale/en.json'
// import pagesConsultationConsultationPageConsultationPageAttendeeLocaleRu from '../pages/consultation/ConsultationPage/ConsultationPageAttendee/locale/ru.json'
// import pagesConsultationConsultationPageConsultationPageConsultantLocaleEn from '../pages/consultation/ConsultationPage/ConsultationPageConsultant/locale/en.json'
// import pagesConsultationConsultationPageConsultationPageConsultantLocaleRu from '../pages/consultation/ConsultationPage/ConsultationPageConsultant/locale/ru.json'
// import pagesConsultationConsultationPageConsultationPageVideoLocaleEn from '../pages/consultation/ConsultationPage/ConsultationPageVideo/locale/en.json'
// import pagesConsultationConsultationPageConsultationPageVideoLocaleRu from '../pages/consultation/ConsultationPage/ConsultationPageVideo/locale/ru.json'
// import pagesConsultationConsultationPageLocaleEn from '../pages/consultation/ConsultationPage/locale/en.json'
// import pagesConsultationConsultationPageLocaleRu from '../pages/consultation/ConsultationPage/locale/ru.json'
// import pagesConsultationConsultationPageInstanceInfoLocaleEn from '../pages/consultation/ConsultationPageInstanceInfo/locale/en.json'
// import pagesConsultationConsultationPageInstanceInfoLocaleRu from '../pages/consultation/ConsultationPageInstanceInfo/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageAttendeeActiveLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageAttendeeActive/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageAttendeeActiveLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageAttendeeActive/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageAttendeeInactiveLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageAttendeeInactive/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageAttendeeInactiveLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageAttendeeInactive/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageConsultantActiveLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageConsultantActive/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageConsultantActiveLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageConsultantActive/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageConsultantInactiveLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageConsultantInactive/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageConsultantInactiveLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageConsultantInactive/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageAttendeeInstanceLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/ConsultationsPageAttendeeInstance/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageAttendeeInstanceLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/ConsultationsPageAttendeeInstance/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageConsultantInstanceLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/ConsultationsPageConsultantInstance/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageConsultantInstanceLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/ConsultationsPageConsultantInstance/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageInstancesLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageInstances/locale/ru.json'
// import pagesConsultationConsultationsPageConsultationsPageOffersLocaleEn from '../pages/consultation/ConsultationsPage/ConsultationsPageOffers/locale/en.json'
// import pagesConsultationConsultationsPageConsultationsPageOffersLocaleRu from '../pages/consultation/ConsultationsPage/ConsultationsPageOffers/locale/ru.json'
// import pagesConsultationConsultationsPageLocaleEn from '../pages/consultation/ConsultationsPage/locale/en.json'
// import pagesConsultationConsultationsPageLocaleRu from '../pages/consultation/ConsultationsPage/locale/ru.json'
// import pagesConsultationNewConsultationPageLocaleEn from '../pages/consultation/NewConsultationPage/locale/en.json'
// import pagesConsultationNewConsultationPageLocaleRu from '../pages/consultation/NewConsultationPage/locale/ru.json'
// import pagesGeneralTeamPageLocaleEn from '../pages/general/TeamPage/locale/en.json'
// import pagesGeneralTeamPageLocaleRu from '../pages/general/TeamPage/locale/ru.json'
// import pagesGeneralUserPageLocaleEn from '../pages/general/UserPage/locale/en.json'
// import pagesGeneralUserPageLocaleRu from '../pages/general/UserPage/locale/ru.json'
// import pagesGroupGroupPageGroupPageActiveStudentsLocaleEn from '../pages/group/GroupPage/GroupPageActiveStudents/locale/en.json'
// import pagesGroupGroupPageGroupPageActiveStudentsLocaleRu from '../pages/group/GroupPage/GroupPageActiveStudents/locale/ru.json'
// import pagesGroupGroupPageGroupPageStudentLocaleEn from '../pages/group/GroupPage/GroupPageStudent/locale/en.json'
// import pagesGroupGroupPageGroupPageStudentLocaleRu from '../pages/group/GroupPage/GroupPageStudent/locale/ru.json'
// import pagesGroupGroupPageGroupPageTeacherLocaleEn from '../pages/group/GroupPage/GroupPageTeacher/locale/en.json'
// import pagesGroupGroupPageGroupPageTeacherLocaleRu from '../pages/group/GroupPage/GroupPageTeacher/locale/ru.json'
// import pagesGroupGroupPageLocaleEn from '../pages/group/GroupPage/locale/en.json'
// import pagesGroupGroupPageLocaleRu from '../pages/group/GroupPage/locale/ru.json'
// import pagesGroupGroupsPageLocaleEn from '../pages/group/GroupsPage/locale/en.json'
// import pagesGroupGroupsPageLocaleRu from '../pages/group/GroupsPage/locale/ru.json'
// import pagesGroupNewGroupStudentPageLocaleEn from '../pages/group/NewGroupStudentPage/locale/en.json'
// import pagesGroupNewGroupStudentPageLocaleRu from '../pages/group/NewGroupStudentPage/locale/ru.json'
// import pagesGuideGuideHomePageLocaleEn from '../pages/guide/GuideHomePage/locale/en.json'
// import pagesGuideGuideHomePageLocaleRu from '../pages/guide/GuideHomePage/locale/ru.json'
// import pagesGuideGuideLessonPageGuideLessonCommitsLocaleEn from '../pages/guide/GuideLessonPage/GuideLessonCommits/locale/en.json'
// import pagesGuideGuideLessonPageGuideLessonCommitsLocaleRu from '../pages/guide/GuideLessonPage/GuideLessonCommits/locale/ru.json'
// import pagesGuideGuideLessonPageGuideLessonFooterLocaleEn from '../pages/guide/GuideLessonPage/GuideLessonFooter/locale/en.json'
// import pagesGuideGuideLessonPageGuideLessonFooterLocaleRu from '../pages/guide/GuideLessonPage/GuideLessonFooter/locale/ru.json'
// import pagesGuideGuideLessonPageLocaleEn from '../pages/guide/GuideLessonPage/locale/en.json'
// import pagesGuideGuideLessonPageLocaleRu from '../pages/guide/GuideLessonPage/locale/ru.json'
// import pagesGuideGuideLessonPageNextGuideLessonCardLocaleEn from '../pages/guide/GuideLessonPage/NextGuideLessonCard/locale/en.json'
// import pagesGuideGuideLessonPageNextGuideLessonCardLocaleRu from '../pages/guide/GuideLessonPage/NextGuideLessonCard/locale/ru.json'
// import pagesGuideGuidePlansLocaleEn from '../pages/guide/GuidePlans/locale/en.json'
// import pagesGuideGuidePlansLocaleRu from '../pages/guide/GuidePlans/locale/ru.json'
// import pagesGuideGuidePlansPageLocaleEn from '../pages/guide/GuidePlansPage/locale/en.json'
// import pagesGuideGuidePlansPageLocaleRu from '../pages/guide/GuidePlansPage/locale/ru.json'
// import pagesGuideGuideSourceCodePageLocaleEn from '../pages/guide/GuideSourceCodePage/locale/en.json'
// import pagesGuideGuideSourceCodePageLocaleRu from '../pages/guide/GuideSourceCodePage/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageAboutLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageAbout/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageAboutLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageAbout/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageArchitectureLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageArchitecture/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageArchitectureLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageArchitecture/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageIntroLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageIntro/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageIntroLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageIntro/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageMockedChatLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageMockedChat/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageMockedChatLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageMockedChat/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPagePlans/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPagePlans/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansShortLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPagePlansShort/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansShortLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPagePlansShort/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageProgramLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageProgram/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageProgramLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageProgram/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageStartLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageStart/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageStartLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageStart/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageTargetLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageTarget/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageTargetLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageTarget/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageYoutubeLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageYoutube/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageYoutubeLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/EdDevPromoPageYoutube/locale/ru.json'
// import pagesPromoCoursesDevWebEdDevPromoPageLocaleEn from '../pages/promo/courses/dev-web/EdDevPromoPage/locale/en.json'
// import pagesPromoCoursesDevWebEdDevPromoPageLocaleRu from '../pages/promo/courses/dev-web/EdDevPromoPage/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPageContactsLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPageContacts/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPageContactsLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPageContacts/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPageIntroLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPageIntro/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPageIntroLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPageIntro/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPageMembersLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPageMembers/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPageMembersLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPageMembers/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPagePlanLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPagePlan/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPagePlanLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPagePlan/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPageProjectsLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPageProjects/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPageProjectsLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPageProjects/locale/ru.json'
// import pagesPromoGeneralClientsPromoPageClientsPageTriggersLocaleEn from '../pages/promo/general/ClientsPromoPage/ClientsPageTriggers/locale/en.json'
// import pagesPromoGeneralClientsPromoPageClientsPageTriggersLocaleRu from '../pages/promo/general/ClientsPromoPage/ClientsPageTriggers/locale/ru.json'
// import pagesPromoGeneralHomePromoPageHomePageIntroLocaleEn from '../pages/promo/general/HomePromoPage/HomePageIntro/locale/en.json'
// import pagesPromoGeneralHomePromoPageHomePageIntroLocaleRu from '../pages/promo/general/HomePromoPage/HomePageIntro/locale/ru.json'
// import pagesPromoGeneralHomePromoPageHomePagePublicationsLocaleEn from '../pages/promo/general/HomePromoPage/HomePagePublications/locale/en.json'
// import pagesPromoGeneralHomePromoPageHomePagePublicationsLocaleRu from '../pages/promo/general/HomePromoPage/HomePagePublications/locale/ru.json'
// import pagesPromoGeneralHomePromoPageHomePageSocialLocaleEn from '../pages/promo/general/HomePromoPage/HomePageSocial/locale/en.json'
// import pagesPromoGeneralHomePromoPageHomePageSocialLocaleRu from '../pages/promo/general/HomePromoPage/HomePageSocial/locale/ru.json'
// import pagesPromoGeneralHomePromoPageHomePageTeamLocaleEn from '../pages/promo/general/HomePromoPage/HomePageTeam/locale/en.json'
// import pagesPromoGeneralHomePromoPageHomePageTeamLocaleRu from '../pages/promo/general/HomePromoPage/HomePageTeam/locale/ru.json'
// import pagesPromoGeneralHomePromoPageLocaleEn from '../pages/promo/general/HomePromoPage/locale/en.json'
// import pagesPromoGeneralHomePromoPageLocaleRu from '../pages/promo/general/HomePromoPage/locale/ru.json'
// import pagesPromoGeneralOldHomePromoPageHomePageIntroLocaleEn from '../pages/promo/general/OldHomePromoPage/HomePageIntro/locale/en.json'
// import pagesPromoGeneralOldHomePromoPageHomePageIntroLocaleRu from '../pages/promo/general/OldHomePromoPage/HomePageIntro/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageContactsLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageContacts/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageContactsLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageContacts/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageEdLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageEd/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageEdLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageEd/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageIntroLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageIntro/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageIntroLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageIntro/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageMembersLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageMembers/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageMembersLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageMembers/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPagePlanLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPagePlan/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPagePlanLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPagePlan/locale/ru.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageProjectsLocaleEn from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageProjects/locale/en.json'
// import pagesPromoGeneralSpecialistsPromoPageSpecialistsPageProjectsLocaleRu from '../pages/promo/general/SpecialistsPromoPage/SpecialistsPageProjects/locale/ru.json'
// @endindex

// const en = {
// @index('../**/locale/en.json', f => `// ...${_.camelCase(f.path)},`)
// ...componentsAuthAuthLocaleEn,
// ...componentsAuthConfirmEmailBlockerLocaleEn,
// ...componentsAuthRestorePasswordLocaleEn,
// ...componentsAuthSignInLocaleEn,
// ...componentsAuthSignUpLocaleEn,
// ...componentsBillingPurchaseProductLocaleEn,
// ...componentsBillingPurchaseProductPaymentButtonPawLocaleEn,
// ...componentsBillingPurchaseProductPaymentButtonVerifoneLocaleEn,
// ...componentsEducationCardsConsultationCardLocaleEn,
// ...componentsEducationCardsEdCardLocaleEn,
// ...componentsEducationCardsGroupCardGroupCardActiveStudentLocaleEn,
// ...componentsEducationCardsGroupCardGroupCardDefaultLocaleEn,
// ...componentsEducationCardsGroupCardGroupCardTeacherLocaleEn,
// ...componentsEducationCardsGuideCardLocaleEn,
// ...componentsFormDatePickerLocaleEn,
// ...componentsFormSelectLocaleEn,
// ...componentsGeneralMembersLocaleEn,
// ...componentsGeneralProjectsLocaleEn,
// ...componentsLayoutsDashboardLayoutLocaleEn,
// ...componentsLayoutsGeneralLayoutLocaleEn,
// ...componentsLayoutsGuideLayoutLessonsMenuLocaleEn,
// ...componentsLayoutsGuideLayoutLocaleEn,
// ...componentsLayoutsIntroLayoutLocaleEn,
// ...componentsOtherGithubInvitationLocaleEn,
// ...componentsOtherPublicUserPreviewLocaleEn,
// ...componentsPagesAccessDeniedPageComponentLocaleEn,
// ...componentsPagesAuthorizedOnlyPageComponentLocaleEn,
// ...componentsPagesErrorPageComponentLocaleEn,
// ...componentsPagesNotFoundPageComponentLocaleEn,
// ...localeEn,
// ...pagesAccountCommuinityPageCommunityDiscordLocaleEn,
// ...pagesAccountCommuinityPageCommunityTelegramLocaleEn,
// ...pagesAccountCommuinityPageLocaleEn,
// ...pagesAccountDashboardPageLocaleEn,
// ...pagesAccountEditProfilePageLocaleEn,
// ...pagesAccountEducationPageLocaleEn,
// ...pagesAccountGuidesPageLocaleEn,
// ...pagesAuthConfirmEmailPageLocaleEn,
// ...pagesAuthConfirmNewEmailPageLocaleEn,
// ...pagesAuthResetPasswordPageLocaleEn,
// ...pagesAuthRestorePasswordPageLocaleEn,
// ...pagesAuthSignInPageLocaleEn,
// ...pagesAuthSignUpPageLocaleEn,
// ...pagesBillingReturnPawPageLocaleEn,
// ...pagesConsultationConsultationPageConsultationPageAttendeeLocaleEn,
// ...pagesConsultationConsultationPageConsultationPageConsultantLocaleEn,
// ...pagesConsultationConsultationPageConsultationPageVideoLocaleEn,
// ...pagesConsultationConsultationPageLocaleEn,
// ...pagesConsultationConsultationPageInstanceInfoLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageAttendeeActiveLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageAttendeeInactiveLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageConsultantActiveLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageConsultantInactiveLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageAttendeeInstanceLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageConsultantInstanceLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesLocaleEn,
// ...pagesConsultationConsultationsPageConsultationsPageOffersLocaleEn,
// ...pagesConsultationConsultationsPageLocaleEn,
// ...pagesConsultationNewConsultationPageLocaleEn,
// ...pagesGeneralTeamPageLocaleEn,
// ...pagesGeneralUserPageLocaleEn,
// ...pagesGroupGroupPageGroupPageActiveStudentsLocaleEn,
// ...pagesGroupGroupPageGroupPageStudentLocaleEn,
// ...pagesGroupGroupPageGroupPageTeacherLocaleEn,
// ...pagesGroupGroupPageLocaleEn,
// ...pagesGroupGroupsPageLocaleEn,
// ...pagesGroupNewGroupStudentPageLocaleEn,
// ...pagesGuideGuideHomePageLocaleEn,
// ...pagesGuideGuideLessonPageGuideLessonCommitsLocaleEn,
// ...pagesGuideGuideLessonPageGuideLessonFooterLocaleEn,
// ...pagesGuideGuideLessonPageLocaleEn,
// ...pagesGuideGuideLessonPageNextGuideLessonCardLocaleEn,
// ...pagesGuideGuidePlansLocaleEn,
// ...pagesGuideGuidePlansPageLocaleEn,
// ...pagesGuideGuideSourceCodePageLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageAboutLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageArchitectureLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageIntroLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageMockedChatLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansShortLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageProgramLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageStartLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageTargetLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageYoutubeLocaleEn,
// ...pagesPromoCoursesDevWebEdDevPromoPageLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPageContactsLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPageIntroLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPageMembersLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPagePlanLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPageProjectsLocaleEn,
// ...pagesPromoGeneralClientsPromoPageClientsPageTriggersLocaleEn,
// ...pagesPromoGeneralHomePromoPageHomePageIntroLocaleEn,
// ...pagesPromoGeneralHomePromoPageHomePagePublicationsLocaleEn,
// ...pagesPromoGeneralHomePromoPageHomePageSocialLocaleEn,
// ...pagesPromoGeneralHomePromoPageHomePageTeamLocaleEn,
// ...pagesPromoGeneralHomePromoPageLocaleEn,
// ...pagesPromoGeneralOldHomePromoPageHomePageIntroLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageContactsLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageEdLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageIntroLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageMembersLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPagePlanLocaleEn,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageProjectsLocaleEn,
// @endindex
// }

// const ru = {
// @index('../**/locale/ru.json', f => `// ...${_.camelCase(f.path)},`)
// ...componentsAuthAuthLocaleRu,
// ...componentsAuthConfirmEmailBlockerLocaleRu,
// ...componentsAuthRestorePasswordLocaleRu,
// ...componentsAuthSignInLocaleRu,
// ...componentsAuthSignUpLocaleRu,
// ...componentsBillingPurchaseProductLocaleRu,
// ...componentsBillingPurchaseProductPaymentButtonPawLocaleRu,
// ...componentsBillingPurchaseProductPaymentButtonVerifoneLocaleRu,
// ...componentsEducationCardsConsultationCardLocaleRu,
// ...componentsEducationCardsEdCardLocaleRu,
// ...componentsEducationCardsGroupCardGroupCardActiveStudentLocaleRu,
// ...componentsEducationCardsGroupCardGroupCardDefaultLocaleRu,
// ...componentsEducationCardsGroupCardGroupCardTeacherLocaleRu,
// ...componentsEducationCardsGuideCardLocaleRu,
// ...componentsFormDatePickerLocaleRu,
// ...componentsFormSelectLocaleRu,
// ...componentsGeneralMembersLocaleRu,
// ...componentsGeneralProjectsLocaleRu,
// ...componentsLayoutsDashboardLayoutLocaleRu,
// ...componentsLayoutsGeneralLayoutLocaleRu,
// ...componentsLayoutsGuideLayoutLessonsMenuLocaleRu,
// ...componentsLayoutsGuideLayoutLocaleRu,
// ...componentsLayoutsIntroLayoutLocaleRu,
// ...componentsOtherGithubInvitationLocaleRu,
// ...componentsOtherPublicUserPreviewLocaleRu,
// ...componentsPagesAccessDeniedPageComponentLocaleRu,
// ...componentsPagesAuthorizedOnlyPageComponentLocaleRu,
// ...componentsPagesErrorPageComponentLocaleRu,
// ...componentsPagesNotFoundPageComponentLocaleRu,
// ...localeRu,
// ...pagesAccountCommuinityPageCommunityDiscordLocaleRu,
// ...pagesAccountCommuinityPageCommunityTelegramLocaleRu,
// ...pagesAccountCommuinityPageLocaleRu,
// ...pagesAccountDashboardPageLocaleRu,
// ...pagesAccountEditProfilePageLocaleRu,
// ...pagesAccountEducationPageLocaleRu,
// ...pagesAccountGuidesPageLocaleRu,
// ...pagesAuthConfirmEmailPageLocaleRu,
// ...pagesAuthConfirmNewEmailPageLocaleRu,
// ...pagesAuthResetPasswordPageLocaleRu,
// ...pagesAuthRestorePasswordPageLocaleRu,
// ...pagesAuthSignInPageLocaleRu,
// ...pagesAuthSignUpPageLocaleRu,
// ...pagesBillingReturnPawPageLocaleRu,
// ...pagesConsultationConsultationPageConsultationPageAttendeeLocaleRu,
// ...pagesConsultationConsultationPageConsultationPageConsultantLocaleRu,
// ...pagesConsultationConsultationPageConsultationPageVideoLocaleRu,
// ...pagesConsultationConsultationPageLocaleRu,
// ...pagesConsultationConsultationPageInstanceInfoLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageAttendeeActiveLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageAttendeeInactiveLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageConsultantActiveLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageConsultantInactiveLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageAttendeeInstanceLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesConsultationsPageConsultantInstanceLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageInstancesLocaleRu,
// ...pagesConsultationConsultationsPageConsultationsPageOffersLocaleRu,
// ...pagesConsultationConsultationsPageLocaleRu,
// ...pagesConsultationNewConsultationPageLocaleRu,
// ...pagesGeneralTeamPageLocaleRu,
// ...pagesGeneralUserPageLocaleRu,
// ...pagesGroupGroupPageGroupPageActiveStudentsLocaleRu,
// ...pagesGroupGroupPageGroupPageStudentLocaleRu,
// ...pagesGroupGroupPageGroupPageTeacherLocaleRu,
// ...pagesGroupGroupPageLocaleRu,
// ...pagesGroupGroupsPageLocaleRu,
// ...pagesGroupNewGroupStudentPageLocaleRu,
// ...pagesGuideGuideHomePageLocaleRu,
// ...pagesGuideGuideLessonPageGuideLessonCommitsLocaleRu,
// ...pagesGuideGuideLessonPageGuideLessonFooterLocaleRu,
// ...pagesGuideGuideLessonPageLocaleRu,
// ...pagesGuideGuideLessonPageNextGuideLessonCardLocaleRu,
// ...pagesGuideGuidePlansLocaleRu,
// ...pagesGuideGuidePlansPageLocaleRu,
// ...pagesGuideGuideSourceCodePageLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageAboutLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageArchitectureLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageIntroLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageMockedChatLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPagePlansShortLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageProgramLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageStartLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageTargetLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageEdDevPromoPageYoutubeLocaleRu,
// ...pagesPromoCoursesDevWebEdDevPromoPageLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPageContactsLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPageIntroLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPageMembersLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPagePlanLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPageProjectsLocaleRu,
// ...pagesPromoGeneralClientsPromoPageClientsPageTriggersLocaleRu,
// ...pagesPromoGeneralHomePromoPageHomePageIntroLocaleRu,
// ...pagesPromoGeneralHomePromoPageHomePagePublicationsLocaleRu,
// ...pagesPromoGeneralHomePromoPageHomePageSocialLocaleRu,
// ...pagesPromoGeneralHomePromoPageHomePageTeamLocaleRu,
// ...pagesPromoGeneralHomePromoPageLocaleRu,
// ...pagesPromoGeneralOldHomePromoPageHomePageIntroLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageContactsLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageEdLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageIntroLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageMembersLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPagePlanLocaleRu,
// ...pagesPromoGeneralSpecialistsPromoPageSpecialistsPageProjectsLocaleRu,
// @endindex
// }

// export const resources = {
//   en: {
//     ...en,
//     all: { ...en },
//   },
//   ru: {
//     ...ru,
//     all: { ...ru },
//   },
// }

export const resources = {
  en: {
    ...locale.en,
    all: { ...locale.en },
  },
  ru: {
    ...locale.ru,
    all: { ...locale.ru },
  },
}

export const getLangByCookies = () => {
  return Cookies.get('lang-svag') === 'ru' ? 'ru' : Cookies.get('lang-svag') === 'en' ? 'en' : null
}

export const setLangToCookies = (lang: Lang) => {
  Cookies.set('lang-svag', lang)
}

const getLangByDevice = () => {
  return Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase().includes('ru') ? 'ru' : 'en'
}

export const getLangByRoutePathname = (pathname: string) => {
  const pathnameFirstPart = pathname.split('/')[1]
  const langByRoutePathname = pathnameFirstPart === 'ru' || pathnameFirstPart === 'en' ? pathnameFirstPart : null
  return langByRoutePathname
}

export const useLangByRouteParams = () => {
  const params = useParams()
  // TODO: it does not work. Lets make own react router
  const langByParams = params.lang as Lang | undefined
  const isLangByParamsBroken = langByParams && !langs.includes(langByParams)
  // const langByParams = isLangByParamsBroken ? null : langByParamsRaw
  return {
    langByParams,
    isLangByParamsBroken,
  }
}

export const useLangByLocationPathname = () => {
  const { pathname } = useLocation()
  return { langByLocationPathname: getLangByRoutePathname(pathname) }
}

export const replaceLangInRoutePathname = (pathname: string, lang: Lang) => {
  const langByRoutePathname = getLangByRoutePathname(pathname)
  return langByRoutePathname ? pathname.replace(`/${langByRoutePathname}`, `/${lang}`) : `${lang}${pathname}`
}

export const removeLangFromRoutePathname = (pathname: string) => {
  const langByRoutePathname = getLangByRoutePathname(pathname)
  return langByRoutePathname ? pathname.replace(`/${langByRoutePathname}`, '') : pathname
}

export const isPathnamesEqualExceptLang = (pathname1: string, pathname2: string) => {
  return removeLangFromRoutePathname(pathname1) === removeLangFromRoutePathname(pathname2)
}

void i18n.use(initReactI18next).init({
  lng: getLangByLocationPathname() || getLangByCookies() || getLangByDevice(),
  resources,
})

export const useLang = () => {
  const { i18n } = useTranslation()
  const updateLang = trpc.updateLang.useMutation()
  const trpcUtils = trpc.useContext()
  const me = useMe()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const setLang = useCallback(
    (lang: Lang) => {
      toast.dismiss()
      void i18n.changeLanguage(lang)
      setLangToCookies(lang)
      navigate(replaceLangInRoutePathname(pathname, lang), { replace: true })
      if (me) {
        void updateLang.mutateAsync({ lang }).then((result) => {
          trpcUtils.getMe.setData(undefined, (prevResult) => {
            if (!prevResult) {
              return prevResult
            }
            return result
          })
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, !!me]
  )
  const lang = i18n.language as Lang
  const humanLang = toHumanLang(lang)
  const formatDateHere = (date: Date | string, formatString: string) => {
    return formatDate(date, formatString, lang)
  }
  return { lang, humanLang, setLang, formatDate: formatDateHere }
}

export const useOnLangChange = (callback: (lang: Lang) => void) => {
  const { lang } = useLang()
  const prevLangRef = useRef<Lang | null>(lang)
  useEffect(() => {
    if (prevLangRef.current !== lang) {
      callback(lang)
      prevLangRef.current = lang
    }
  }, [lang, callback])
}

export const toHumanLang = (lang: Lang) => {
  return {
    ru: 'Русский (Ru)',
    en: 'English (En)',
  }[lang]
}

export const useT = useTranslation

export const tlt = ((...args) => (i18n.t as any)(`all:${args[0]}` as any, ...(args.slice(1) as any))) as ReturnType<
  typeof useT<'all'>
>['t']

export const useAllT = <T extends UseTranslationOptions<any>>(options?: T) => {
  return useTranslation('all', options)
}

type TransProps = React.ComponentProps<typeof Trans>
export const T = ({ k, ...restProps }: Omit<TransProps, 'i18nKey' | 't'> & { k: TransProps['i18nKey']; t: any }) => (
  <Trans i18nKey={k} {...restProps} />
)

const toastIds: string[] = []
export const LangNormalizer = ({ children }: { children: React.ReactNode }) => {
  const { lang, setLang } = useLang()
  // const { langByParams, isLangByParamsBroken } = useLangByRouteParams()
  const { langByLocationPathname } = useLangByLocationPathname()
  const { pathname, search, hash } = useLocation()
  const fullPath = `${pathname}${search}${hash}`
  const navigate = useNavigate()
  const { unavailableLangsDataMessage, forceUnavailableLangsDataMessage, availableLangsData } = useAvailableLangsData()
  const langByCookies = getLangByCookies()
  const trpcUtils = trpc.useContext()

  // Set lang by route
  // useEffect(() => {
  //   if (langByParams && !isLangByParamsBroken) {
  //     setLang(langByParams)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [langByParams, isLangByParamsBroken])

  useOnLangChange((lang) => {
    void trpcUtils.invalidate()
  })

  // Set lang to cookies of not set
  useEffect(() => {
    if (lang && !langByCookies) {
      setLangToCookies(lang)
    }
  }, [lang, langByCookies])

  // Redirect to correct lang by cookies
  const shouldRedirectBecouseOfCookies =
    langByCookies && langByLocationPathname && langByLocationPathname !== langByCookies
  useEffect(() => {
    if (shouldRedirectBecouseOfCookies) {
      setLang(langByCookies)
      navigate(replaceLangInRoutePathname(fullPath, langByCookies), { replace: true })
    }
  }, [shouldRedirectBecouseOfCookies, langByCookies, setLang, fullPath, navigate])

  // Redirect to correct lang page by lang
  const shouldRedirectBecouseNoLangLocationPathname = !langByLocationPathname
  useEffect(() => {
    if (shouldRedirectBecouseNoLangLocationPathname) {
      navigate(`/${lang}${fullPath}`)
    }
  }, [shouldRedirectBecouseNoLangLocationPathname, lang, navigate, fullPath])

  // Redirect to base lang page if isLangByParamsBroken
  // useEffect(() => {
  //   if (isLangByParamsBroken) {
  //     navigate(pathname.replace(`/${langByParams}`, ''), { replace: true })
  //   }
  // }, [isLangByParamsBroken, langByParams, navigate, pathname])

  // Notify about unavailable langs
  useEffect(() => {
    if (unavailableLangsDataMessage && forceUnavailableLangsDataMessage) {
      toastIds.map(toast.dismiss)
      toastIds.splice(0, toastIds.length)

      const toastId = notify({
        message: unavailableLangsDataMessage,
        type: 'neutral',
        icon: '📝',
      })
      toastIds.push(toastId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(availableLangsData), unavailableLangsDataMessage, forceUnavailableLangsDataMessage])

  // Track user data lang
  useEffect(() => {
    trackUserData({ lang })
  }, [lang])

  const isLoading = shouldRedirectBecouseOfCookies || shouldRedirectBecouseNoLangLocationPathname
  if (isLoading) {
    return <Loader type="page" />
  }

  return children
}

const availableLangsDataStore = atom<{ langs: Lang[]; pathnameLangless: string }>({
  langs: ['ru', 'en'],
  pathnameLangless: '/',
})
export const setAvailableLangsData = ({ langs, pathname }: { langs: Lang[]; pathname: string }) => {
  availableLangsDataStore.set({ langs, pathnameLangless: removeLangFromRoutePathname(pathname) })
}
export const useAvailableLangsData = (props?: { langs: Lang[]; pathname: string }) => {
  const { lang } = useLang()
  useEffect(() => {
    if (props) {
      setAvailableLangsData(props)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props)])
  const availableLangsData = useStore(availableLangsDataStore)
  const unavailableLangsDataMessage = 'This page is not available on your language, but will be translated later'
  const forceUnavailableLangsDataMessage = !availableLangsData.langs.includes(lang)
  return { availableLangsData, unavailableLangsDataMessage, forceUnavailableLangsDataMessage, setAvailableLangsData }
}
