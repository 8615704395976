import { useCurrency } from '../../../../../lib/currency'
import { useT } from '../../../../../lib/i18n'
import css from './index.module.scss'

export const HomePageOffer = () => {
  const { t } = useT('homePageOffer')
  const { normalizePricesWithCurrency } = useCurrency()
  return (
    <div className={css.social} id="social">
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle}>
          <span className={css.sectionTitleText}>{t('title')}</span>
        </h2>
        <div className={css.intro} dangerouslySetInnerHTML={{ __html: normalizePricesWithCurrency(t('article')) }} />
      </div>
    </div>
  )
}
